import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { GridList, GridListItem } from '../components/GridList'
import Layout from '../components/Layout'
import PagePadding from '../components/PagePadding'
import Seo from '../components/Seo'
import SuiteTile from '../components/SuiteTile'
import PageData from '../types/PageData'
import { ISemiCustomSuite } from '../types/SemiCustomSuite'

interface Props {
  data: {
    sanityPage: PageData
    allSanitySemiCustomSuite: {
      nodes: ISemiCustomSuite[]
    }
  }
}

function CollectionPage({ data }: Props) {
  const {
    allSanitySemiCustomSuite: { nodes: suites },
  } = data
  return (
    <Layout pageData={data?.sanityPage} pageTitle="The Collection">
      <Seo
        title="The Collection"
        description="My collection of semi-custom designs allows you to pick and choose your favourite elements, materials, ink colours and embellishments."
      />
      <Content>
        <GridList>
          {suites.map(suite => {
            const { id, ...suiteInfo } = suite
            return (
              <GridListItem key={id}>
                <SuiteTile {...suiteInfo} />
              </GridListItem>
            )
          })}
        </GridList>
      </Content>
    </Layout>
  )
}

export default CollectionPage

export const query = graphql`
  query CollectionPageQuery {
    sanityPage(uuid: { eq: "semi-custom-collection" }) {
      _rawPageMessage
    }
    allSanitySemiCustomSuite(
      sort: { order: ASC, fields: [displayOrder, name] }
    ) {
      nodes {
        id
        name
        description
        mainImage {
          asset {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 735)
          }
        }
      }
    }
  }
`

const Content = styled(PagePadding)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 1020px;
  margin: 0 auto;
`
