import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ISemiCustomSuite } from '../types/SemiCustomSuite'
import { createSlug } from '../utils'
import Paragraph from './Paragraph'
import SerifText from './SerifText'

type SuiteTileProps = Omit<
  ISemiCustomSuite,
  'id' | 'invitationCardImage' | 'rsvpCardImage' | 'saveTheDateCardImage'
>

function SuiteTile({ name, description, mainImage }: SuiteTileProps) {
  const linkTarget = `/collection/${createSlug(name)}`
  return (
    <Figure>
      <Link to={linkTarget} title={`View ${name} semi-custom suite`}>
        <GatsbyImage
          image={mainImage.asset.gatsbyImageData}
          alt={mainImage.alt || ''}
        />
      </Link>
      <Paragraph>Click the image to view more of this suite.</Paragraph>
      <figcaption>
        <SuiteName>{name}</SuiteName>
        <Paragraph>{description}</Paragraph>
      </figcaption>
    </Figure>
  )
}

export default SuiteTile

const Figure = styled.figure`
  margin: 0;
`

const SuiteName = styled(SerifText)`
  font-size: 2rem;
  margin: 0;
`
